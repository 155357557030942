import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Grid, FormControlLabel, TextField, Typography, IconButton, Link } from '@mui/material';
import { toast } from 'react-toastify';
import Layout from '../Layout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomerInfoForm from '../components/customerInfoForm';
import PaymentInfoForm from '../components/paymentInfoForm';
import DOMPurify from 'dompurify';
import 'react-toastify/dist/ReactToastify.css';

export default function ArchiveRequest() {
  const [products, setProducts] = useState([]);
  const [formats, setFormats] = useState([]);
  const [pastOrders, setPastOrders] = useState();
  const [isChecked, setChecked] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState('');
  const [orderResponse, setOrderResponse] = useState('');
  const [showAdditionalCheckbox, setShowAdditionalCheckbox] = useState(false);
  const [errorsByOrderIndex, setErrorsByOrderIndex] = useState({});
  const [orders, setOrders] = useState([createEmptyOrder()]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const { user } = useAuth();
  const REQUEST_ID = '82737BED-3FB8-4748-B1D0-5B8E57C1FEC3';
  const ORDER_LIMIT = 8;
  const COST_PER_ORDER = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call the API to pull product data
        const productResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/product/${REQUEST_ID}`);
        const productData = await productResponse.json();
        setProducts(productData.data);

        // Call the API to pull format data
        const formatResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/format/${REQUEST_ID}`);
        const formatData = await formatResponse.json();
        setFormats(formatData.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [REQUEST_ID]); // Include the dependency array with the REQUEST_ID

  // Group products by category_id
  const groupedProducts = {};

  products.forEach((product) => {
    if (!groupedProducts[product.category_id]) {
      groupedProducts[product.category_id] = [];
    }
    groupedProducts[product.category_id].push(product);
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => { // Add accordion change event handler
    setExpanded(isExpanded ? panel : false);
  };

  const handleProductChange = (orderIndex, event) => {
    const selectedProductId = event.target.value;
    const updatedOrders = orders.map((order, index) => {
      if (index === orderIndex) {
        return { ...order, selectedProduct: selectedProductId };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  const handleOrderQuestionChange = (orderIndex, questionIndex, event) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].orderQuestions[questionIndex].answer = event.target.value;
    setOrders(updatedOrders);
  };

  const handleFormatChange = (orderIndex, formatId) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].format = formatId; // Set the format as the selected format_id
    updatedOrders[orderIndex].orderQuestions[5].answer = false;
    setOrders(updatedOrders);

    // Check if the specific format is selected
    if (formatId === '9F57FE84-E295-4534-ACE9-40A0C9F5CCD9') {
      setShowAdditionalCheckbox(true);
    } else {
      setShowAdditionalCheckbox(false);
    }
  };

  const handleCertificationCheckboxChange = (orderIndex, event) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].orderQuestions[5].answer = event.target.checked; // Update the answer for the certification checkbox
    setOrders(updatedOrders);
  };

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const addOrder = () => {
    if (orders.length < ORDER_LIMIT) {
      setOrders([...orders, createEmptyOrder()]);
    }
  };

  const calculateTotalAmount = () => {
    const currentOrderCount = orders.length;
    return currentOrderCount * COST_PER_ORDER;
  };

  const removeOrder = (orderIndex) => {
    const updatedOrders = [...orders];
    updatedOrders.splice(orderIndex, 1);
    setOrders(updatedOrders);
  };

  // Create a custom validation function
  const validateForm = () => {
    const errors = [];
    const accordionErrors = [];
    const errorsByOrderIndex = {};

    orders.forEach((order, orderIndex) => {

      // Initialize an empty array for orderIndex
      errorsByOrderIndex[orderIndex] = [];

      if (!order.selectedProduct) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Product not selected.`);
        accordionErrors.push(orderIndex);
      }
      //
      // Ginny requested that these questions no be required, commenting out for now incase they change their mind. 
      //
      // if (!order.orderQuestions[0].answer) {
      //   errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Full name of person to be searched not provided`);
      //   accordionErrors.push(orderIndex);
      // }
      // if (!order.orderQuestions[1].answer) {
      //   errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Specific record requested not provided.`);
      //   accordionErrors.push(orderIndex);
      // }
      // if (!order.orderQuestions[2].answer) {
      //   errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: County or City where recorded not provided.`);
      //   accordionErrors.push(orderIndex);
      // }
      // if (!order.orderQuestions[3].answer) {
      //   errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Date (or approximate date) recorded not provided.`);
      //   accordionErrors.push(orderIndex);
      // }
      if (!order.format) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Format not selected.`);
        accordionErrors.push(orderIndex);
      }

    });

    setExpanded(accordionErrors[0]);
    setErrorsByOrderIndex(errorsByOrderIndex);

    return errorsByOrderIndex;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form Submitted');
    setExpanded();

    const errorsByOrderIndex = validateForm();
    const totalErrorsCount = Object.values(errorsByOrderIndex)
      .reduce((total, errorsArray) => total + errorsArray.length, 0);

    if (totalErrorsCount > 0) {
      console.log("Invalid");

      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return;
    }

    // Disable the submit button while form is being submitted
    setIsSubmitting(true);

    // Set transaction id variable 
    const TRANSACTION_ID = uuidv4().toUpperCase();

    // Sanitizate Inputs 
    const customerName = DOMPurify.sanitize(event.target.name.value).replace(/[^a-zA-Z\s]/g, '');
    const customerEmail = DOMPurify.sanitize(event.target.email.value);
    const customerAddress = DOMPurify.sanitize(event.target.address.value).replace(/\./g, '').trim();
    const customerCity = DOMPurify.sanitize(event.target.city.value).trim();
    const customerState = event.target.state ? DOMPurify.sanitize(event.target.state.value).trim() : '';
    const customerCountry = event.target.country ? DOMPurify.sanitize(event.target.country.value).trim() : 'United States';
    const customerZip = DOMPurify.sanitize(event.target.zip.value).trim();
    const customerPhone = DOMPurify.sanitize(event.target.phone.value).replace(/\D/g, '');

    // Get the customer information from the form fields
    const customer = {
      name: customerName,
      email: customerEmail,
      address: `${customerAddress} ${customerCity}, ${customerState}, ${customerZip}, ${customerCountry}`,
      phone: customerPhone,
    };

    // Get card information from the form field
    const card = {
      name: customerName,
      company: "Library of Virginia",
      phone: customerPhone,
      email: customerEmail,
      number: event.target.cardNumber.value,
      expirationDate: event.target.expirationDate.value,
      cvv: event.target.cardCode.value,
      amount: calculateTotalAmount(),
      address: customerAddress,
      city: customerCity,
      state: customerState,
      zip: customerZip,
      country: customerCountry,
      transaction_id: TRANSACTION_ID,
      user: user ? user.user_id : null
    }

    // This builds the request body for order limit
    const limitReq = {
      user: user ? user.user_id : null,
      email: customerEmail,
      phone: customerPhone,
      request_id: REQUEST_ID,
    };

    // Prepare the data for submission
    const submittedOrders = orders.map((order) => {
      const submittedOrderQuestions = {};
      order.orderQuestions.forEach((questionObj) => {
        submittedOrderQuestions[questionObj.question] = questionObj.answer;
      });

      return {
        product: order.selectedProduct,
        format: order.format,
        details: submittedOrderQuestions,
      };
    });

    // Call API to check order count
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/order/limit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(limitReq),
      });
      const json = await response.json();
      setPastOrders(json.data);

      // This calculates the current order amount
      const currentOrderCount = submittedOrders.length + pastOrders;

      // Run checks before charging card and comitting to DB 
      if (currentOrderCount > ORDER_LIMIT) {
        toast.error(`Unfortunately you have reached the request limit of 8 this month. This order will take you over the threshold: ${currentOrderCount}/${ORDER_LIMIT}`);
        setIsSubmitting(false);
        return; // Stop the form submission
      }

    } catch (error) {
      toast.error(error);
      setIsSubmitting(false);
      return;
    }

    // Call API to process payment
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payment/converge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(card),
      });

      const xmlResponse = await response.text(); // Get the XML response as text
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

      // Extract data from the parsed XML
      const txnElement = xmlDoc.getElementsByTagName('txn')[0];
      const ssl_issuer_response = txnElement.getElementsByTagName('ssl_issuer_response')[0]?.textContent;
      const ssl_company = txnElement.getElementsByTagName('ssl_company')[0]?.textContent;
      const ssl_result_message = txnElement.getElementsByTagName('ssl_result_message')[0]?.textContent;
      const ssl_card_number = txnElement.getElementsByTagName('ssl_card_number')[0]?.textContent;
      const ssl_email = txnElement.getElementsByTagName('ssl_email')[0]?.textContent;
      const ssl_merchant_txn_id = txnElement.getElementsByTagName('ssl_merchant_txn_id')[0]?.textContent;


      // Create an object with the extracted data
      const paymentResponseData = {
        ssl_issuer_response,
        ssl_company,
        ssl_result_message,
        ssl_card_number,
        ssl_email,
        ssl_merchant_txn_id
      };

      setPaymentResponse(paymentResponseData);

      if (paymentResponseData.ssl_result_message !== "APPROVAL") {
        toast.error(`Transaction not approved. Please check details.`);
        setIsSubmitting(false);
        return;
      }

    } catch (error) {
      toast.error(`Payment Error: ${error}`);
      setIsSubmitting(false);
      return;
    }

    // Create the final payload
    const payload = {
      orders: submittedOrders,
      customer: customer,
      user: user ? user.user_id : null,
      request: REQUEST_ID,
      transaction: TRANSACTION_ID
    };

    // Call API to process order 
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/order/archive`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      setOrderResponse(response);

    } catch (error) {
      toast.error(`Error Processing Order: ${error}`);
      setIsSubmitting(false);
      return;
    }

    // Add a slight delay before clearing the form fields
    setTimeout(() => {
      // Set formSubmitted to true before clearing the payment fields
      setFormSubmitted(true);

      // Clear the form fields
      event.target.reset();

      // Set the form fields in the state back to their initial values
      setOrders([createEmptyOrder()]);
      setExpanded(false);
      setPaymentResponse('');

      // Reset isSubmitting to false after the form submission is complete
      setIsSubmitting(false);

      // Display success message
      toast.success('Order Fulfilled. Check Email!');
    }, 200);
  };

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Archives Request Form
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <br></br>
              <div>
                <Typography variant="body1" paragraph>
                <strong>A non-refundable $10 service fee is required</strong> for each request. The service fee includes a search for one person in one record from specific indexed materials. Please be aware that a search may not locate the information that you seek. If extended or in-depth research is required, a list of professional researchers familiar with our holdings will be furnished.
                </Typography>

                <Typography variant="body1" paragraph>
                  The <strong>$10 service fee</strong> includes up to 20 photocopies, PDFs, <strong>or</strong> 300 dpi JPGs from the service copy (microfilm, Photostats, etc.) of the record. If copies exceed this limit, an invoice for additional copy and shipping costs will be sent and must be paid before the order is processed. Please be sure to note the format needed. Records that have been microfilmed will be copied/scanned from the microfilm and not the original record. We reserve the right not to copy materials that could be damaged by photocopying.
                </Typography>

                <Typography variant="body1" paragraph>
                  The $10 service fee does not include reproductions of oversized materials.  Please contact Archives Reference Services if you believe the material may be oversized.
                </Typography>

                <Typography variant="body1" paragraph>
                  There is a limit of <strong>8 requests</strong> per person/company/address per month. Please allow <strong>4 to 8 weeks</strong> for processing. Multiple orders may not all be completed at the same time. Please select only one record per request.
                </Typography>

                <Typography variant="body1">
                  Please note that the following records cannot be obtained from the Library of Virginia:
                </Typography>

                <List component="ul" >
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <span style={{ fontWeight: 'bold' }}>Census Records:</span> We neither search nor copy the federal census schedules.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <span style={{ fontWeight: 'bold' }}>Military Service Records:</span> Revolutionary War, Confederate, and other official service records must be obtained from the
                          National Archives & Records Administration in Washington, D.C.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <span style={{ fontWeight: 'bold' }}>Most Mental Health Hospital Medical Records:</span> Please contact Archives Reference Services at 804.692.3888 or <a href='mailto:archdesk@lva.virginia.gov'>archdesk@lva.virginia.gov</a> for options regarding mental health/medical records before sending in a request form as there are legal and privacy restrictions regarding copies.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <span style={{ fontWeight: 'bold' }}>Most Virginia Vital Records:</span> Please contact Archives Reference Services at 804.692.3888 or <a href='mailto:archdesk@lva.virginia.gov'>archdesk@lva.virginia.gov</a> for options regarding vital records before sending in a request form for vital records as there are copy restrictions.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <span style={{ fontWeight: 'bold' }}>Virginia Colonial Records Project:</span> The Library of Virginia does not hold reproduction rights. Copies must be obtained
                          from the repository holding the original records.
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </div>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" gutterBottom>
                Total: ${calculateTotalAmount().toFixed(2)}
              </Typography>
              {Object.values(errorsByOrderIndex).reduce((total, errorsArray) => total + errorsArray.length, 0) > 0 && (
                <Box marginBottom={2} sx={{ border: '1px', borderRadius: '4px', padding: '16px' }}>
                  <Alert severity="error">
                    <AlertTitle>Validation Errors</AlertTitle>
                    There are errors in the following order(s):
                    <ul>
                      {Object.keys(errorsByOrderIndex).map((orderIndex) => (
                        <li key={orderIndex}>{`Order #${parseInt(orderIndex) + 1}`}</li>
                      ))}
                    </ul>
                  </Alert>
                </Box>
              )}
              {/* Orders */}
              {orders.map((order, orderIndex) => (
                <Accordion key={orderIndex} expanded={expanded === orderIndex} onChange={handleAccordionChange(orderIndex)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h6" gutterBottom style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                          Order #{orderIndex + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {orderIndex > 0 && (
                          <IconButton
                            style={{ color: 'red' }}
                            onClick={() => removeOrder(orderIndex)}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box marginBottom={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px' }}>
                      <Grid container spacing={2}>
                        {/* Product Questions */}
                        <Grid item xs={12}>
                          {errorsByOrderIndex[orderIndex] && errorsByOrderIndex[orderIndex].length > 0 && (
                            <Box marginBottom={2} sx={{ border: '1px', borderRadius: '4px', padding: '16px' }}>
                              <Alert severity="error">
                                <AlertTitle>Order #{orderIndex + 1} Error(s)</AlertTitle>
                                Please correct the following error(s):
                                <ul>
                                  {errorsByOrderIndex[orderIndex].map((error, index) => (
                                    <li key={index}>{error}</li>
                                  ))}
                                </ul>
                              </Alert>
                            </Box>
                          )}
                          <Typography variant="h6" gutterBottom>
                            Record Type
                          </Typography>
                          {Object.entries(groupedProducts).map(([categoryId, categoryProducts]) => (
                            <Accordion key={categoryId}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{categoryProducts[0].category_name}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={2}>
                                  {categoryProducts.map((product) => (
                                    <Grid item xs={12} key={product.product_id}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={order.selectedProduct === product.product_id}
                                            onChange={(event) => handleProductChange(orderIndex, event)}
                                            value={product.product_id}
                                          />
                                        }
                                        label={product.product_name}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="p" gutterBottom>
                              ** - Record is available in digital format on the Library of Virginia website.
                            </Typography>
                          </Box>
                        </Grid>

                        {/* Order Questions */}
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Orders
                          </Typography>
                          <Box mb={2}>
                            <TextField
                              label="Full name of person to be searched:"
                              name="Full name of person to be searched:"
                              value={order.orderQuestions[0].answer || ''}
                              onChange={(event) => handleOrderQuestionChange(orderIndex, 0, event)}
                              fullWidth
                            />
                          </Box>
                          <Box mb={2}>
                            <TextField
                              label="Specific record requested:"
                              name="Specific record requested:"
                              value={order.orderQuestions[1].answer || ''}
                              onChange={(event) => handleOrderQuestionChange(orderIndex, 1, event)}
                              fullWidth
                            />
                          </Box>
                          <Box mb={2}>
                            <TextField
                              label="County or City where recorded:"
                              name="County or City where recorded:"
                              value={order.orderQuestions[2].answer || ''}
                              onChange={(event) => handleOrderQuestionChange(orderIndex, 2, event)}
                              fullWidth
                            />
                          </Box>
                          <Box mb={2}>
                            <TextField
                              label="Date (or approximate date) recorded:"
                              name="Date (or approximate date) recorded:"
                              value={order.orderQuestions[3].answer || ''}
                              onChange={(event) => handleOrderQuestionChange(orderIndex, 3, event)}
                              fullWidth
                            />
                          </Box>
                          <Box mb={2}>
                            <TextField
                              label="Additional information:"
                              name="Additional information:"
                              value={order.orderQuestions[4].answer || ''}
                              onChange={(event) => handleOrderQuestionChange(orderIndex, 4, event)}
                              fullWidth
                            />
                          </Box>
                        </Grid>

                        {/* Format */}
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Format (Choose One)
                          </Typography>
                          {formats.map((format) => (
                            <FormControlLabel
                              key={format.format_id}
                              control={
                                <Checkbox
                                  checked={order.format === format.format_id}
                                  onChange={() => handleFormatChange(orderIndex, format.format_id)}
                                />
                              }
                              label={format.format_name}
                              name="format"
                            />
                          ))}
                        </Grid>
                        {/* Additional Checkbox */}
                        {showAdditionalCheckbox && (
                          <Grid item xs={12}>

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={order.orderQuestions[5].answer}
                                  onChange={(event) => handleCertificationCheckboxChange(orderIndex, event)} // Use the new event handler
                                />
                              }
                              label="Please certify with Library of Virginia stamp."
                              name="Please certify with Library of Virginia stamp."
                            />
                          </Grid>
                        )}

                        {orders.length > 1 && (
                          <Grid item xs={12}>
                            <Button variant="outlined" onClick={() => removeOrder(orderIndex)}>
                              Remove Order
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            {orders.length < ORDER_LIMIT && (
              <Grid item xs={12}>
                <Button variant="outlined" onClick={addOrder}>
                  Add Order
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomerInfoForm />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" gutterBottom>
                Payment Details:
              </Typography>
              <PaymentInfoForm formSubmitted={formSubmitted} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Before submitting, please contact Archives Reference Services at{' '}
                <Link href="mailto:archdesk@lva.virginia.gov" color="inherit">
                  archdesk@lva.virginia.gov
                </Link>{' '}
                or call 804.692.3888 if you have any questions.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="I understand that the service fee is non-refundable."
              />
            </Grid>
            {isChecked && 
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                disabled={isSubmitting}
                >
                {isSubmitting ? 'Processing...' : 'Submit'}
              </Button>
            </Grid>
          }
          </Grid>
        </form>
      </Box>
    </Layout>
  );
}

// Utility function to create an empty order object
function createEmptyOrder() {
  return {
    selectedProduct: '',
    orderQuestions: [
      { question: 'Full name of person to be searched:', answer: '' },
      { question: 'Specific record requested:', answer: '' },
      { question: 'County or City where recorded:', answer: '' },
      { question: 'Date (or approximate date) recorded:', answer: '' },
      { question: 'Additional information:', answer: '' },
      { question: 'Please certify with Library of Virginia stamp.', answer: false }
    ],
    format: ''
  };
}