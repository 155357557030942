import Layout from '../Layout';
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';


export default function RequestTypes() {
    const requests = [
       // { text: 'Archival Materials Requests', path: '/request/archives' },
       // { text: 'Published Materials Requests', path: '/request/published-materials' },
        { text: 'Special Collections Requests', path: '/request/special-collections' }
    ];

  return (
    <Layout>
      <Box display="flex" justifyContent="center" alignItems="center">
      {requests.map((request) => (
        <Button
          key={request.text}
          component={Link}
          to={request.path}
          variant="contained"
          color="primary"
          style={{ margin: '0.5rem' }}
        >
          {request.text}
        </Button>
      ))}
    </Box>
    </Layout>
  );
}
